import React, { useEffect, useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useAnimation, motion, AnimatePresence } from "framer-motion"
import { useInView } from "react-intersection-observer"

const sectionAnim = {
  // initial: {
  //   y: 20,
  // },
  visible: {
    // y: 0,
    transition: {
      // when: "beforeChildren",
      // delayChildren: 0.4,
      staggerChildren: 0.15,
      staggerDirection: 1,
      duration: 1,
    },
  },
}

const sectionItem = {
  initial: {
    opacity: 0,
    y: 10,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      // when: "beforeChildren",
      // delayChildren: 0.4,
      staggerChildren: 0.15,
      duration: 0.3,
    },
  },
}
const animImage = {
  initial: {
    scale: 1.1,
  },
  visible: {
    scale: 1,
    transition: {
      // when: "beforeChildren",
      staggerChildren: 0.1,
      duration: 1,
      // ease: [0.16, 1, 0.3, 1]
    },
  },
}

const WorkHeader = ({ image, title }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    threshold: 0.5,
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <motion.section
      ref={ref}
      animate={controls}
      initial="initial"
      variants={sectionAnim}
      className="bg-primary text-white relative overflow-hidden"
    >
      <motion.div variants={animImage} className="flex justify-end w-full h-full">
        <GatsbyImage
          className="h-[60vh] rounded-bl-[6rem] lg:rounded-bl-[12rem]"
          image={image.asset.gatsbyImageData}
          alt={image.asset.altText}
        />
      </motion.div>

      <div className="absolute z-10 inset-0 flex items-center justify-center">
        <div className="container">
          <motion.h1 variants={sectionItem} className="h1 uppercase">{title}</motion.h1>
        </div>
      </div>
    </motion.section>
  )
}

export default WorkHeader
