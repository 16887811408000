import React, { useEffect } from "react"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import WorkPostBuilder from "../components/workPostBuilder"
import ReactPlayer from "react-player"
import Layout from "../components/layout"
import WorkHeader from "../components/work-header"
import { PortableText } from "@portabletext/react"
import VideoCarousel from "../components/video-carousel"

const motionSection = {
  initial: {
    opacity: 0,
    y: 16,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      // when: "beforeChildren",
      staggerChildren: 0.15,
      staggerDirection: 1,
      duration: 0.6,
    },
  },
}

const motionItem = {
  initial: {
    opacity: 0,
    y: 16,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      // when: "beforeChildren",
      // delayChildren: 0.4,
      staggerChildren: 0.15,
      duration: 0.6,
      // ease: [0.16, 1, 0.3, 1]
    },
  },
}

const WorkPostTemplate = props => {
  const { data } = props
  const post = data && data.post

  console.log("data", data)

  const controls = useAnimation()
  const [ref, inView] = useInView({
    threshold: 0.1,
  })

  useEffect(() => {
    if (inView) {
      controls.start("animate")
    }
  }, [controls, inView])

  return (
    <Layout pageSeo={post}>
      <WorkHeader image={post.featuredImage} title={post.title} />

      <motion.section
        className="section bg-primary text-white"
        variants={motionSection}
        ref={ref}
        animate={controls}
        initial="initial"
      >
        <div className="container">
          <div className="lg:grid lg:grid-cols-12 gap-8">
            <div className="lg:col-span-4">
              <div className="p-6 rounded-lg shadow-2xl shadow-stone-400/20 bg-primary-3 text-white sticky top-[120px]">
                {" "}
                <h3 className="h2 font-bold uppercase mb-4">Work done</h3>
                <ul className="grid divide-y divide-white/50 divide-y-4">
                  {post.workTags.map((item, index) => {
                    return (
                      <motion.li
                        variants={motionItem}
                        className="tracking-wide py-2"
                      >
                        {item.tag}
                      </motion.li>
                    )
                  })}
                </ul>
              </div>
            </div>

            <div className="lg:col-span-8">
              <div className="mb-8">
                <VideoCarousel data={post.video} />
              </div>

              <div className="prose prose-lg prose-invert text-white">
                <PortableText
                  value={post._rawPortableTextAbout}
                  onMissingComponent={false}
                />
              </div>
            </div>
          </div>
        </div>
      </motion.section>

      <section className="section bg-accent" data-scroll-section data-scroll>
        <div className="container">
          <div className="divide-y">
            <div className="grid lg:grid-cols-12 py-12">
              <div className="col-span-4">
                <h3 className="h1">Key takeaways</h3>
              </div>
              <div className="col-span-8">
                <div className="prose prose-lg text-white">
                  <PortableText
                    value={post._rawPortableTextKeyTakeAways}
                    onMissingComponent={false}
                  />
                </div>
              </div>
            </div>
            <div className="grid lg:grid-cols-12 py-12">
              <div className="col-span-4">
                <h3 className="h1">Our Approach</h3>
              </div>
              <div className="col-span-8">
                <div className="prose prose-lg prose-invert text-white">
                  <PortableText
                    value={post._rawPortableTextOurApproach}
                    onMissingComponent={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default WorkPostTemplate

export const query = graphql`
  query WorkPostTemplateQuery($id: String!) {
    post: sanityWork(id: { eq: $id }) {
      id
      _rawPortableTextAbout
      _rawPortableTextKeyTakeAways
      _rawPortableTextOurApproach
      title
      publishedAt
      workTags {
        _key
        _type
        tag
      }
      slug {
        current
      }
      featuredImage {
        asset {
          gatsbyImageData
        }
      }
      video {
        _type
        _key
        vimeoUrl
      }
      metaDesc
      metaTitle
      shareGraphic {
        asset {
          url
        }
      }
    }
  }
`
